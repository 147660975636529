<!-- 
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
	<div>

		<!-- Dashboard Layout -->
		<a-layout class="layout-dashboard" id="layout-dashboard"
			:class="[navbarFixed ? 'navbar-fixed' : '', !sidebarCollapsed ? 'has-sidebar' : '', layoutClass]">

			<!-- Main Sidebar -->
			<DashboardSidebar :sidebarCollapsed="sidebarCollapsed" :sidebarColor="sidebarColor" :sidebarTheme="sidebarTheme"
				@toggleSidebar="toggleSidebar"></DashboardSidebar>
			<!-- / Main Sidebar -->

			<!-- Layout Content -->
			<a-layout>

				<!-- Layout Header's Conditionally Fixed Wrapper -->
				<DashboardHeader :sidebarCollapsed="sidebarCollapsed" :navbarFixed="navbarFixed"
					@toggleSettingsDrawer="toggleSettingsDrawer" @toggleSidebar="toggleSidebar"></DashboardHeader>
				<!-- / Layout Header's Conditionally Fixed Wrapper -->

				<!-- Page Content -->
				<a-layout-content>
					<router-view />
				</a-layout-content>
				<!-- / Page Content -->

				<!-- Layout Footer -->
				<DashboardFooter></DashboardFooter>
				<!-- / Layout Footer -->


				<!-- Floating Action Button For Toggling Settings Drawer 
    			<a-button class="fab" shape="circle" @click="showSettingsDrawer = true">
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4892 3.17094C11.1102 1.60969 8.8898 1.60969 8.51078 3.17094C8.26594 4.17949 7.11045 4.65811 6.22416 4.11809C4.85218 3.28212 3.28212 4.85218 4.11809 6.22416C4.65811 7.11045 4.17949 8.26593 3.17094 8.51078C1.60969 8.8898 1.60969 11.1102 3.17094 11.4892C4.17949 11.7341 4.65811 12.8896 4.11809 13.7758C3.28212 15.1478 4.85218 16.7179 6.22417 15.8819C7.11045 15.3419 8.26594 15.8205 8.51078 16.8291C8.8898 18.3903 11.1102 18.3903 11.4892 16.8291C11.7341 15.8205 12.8896 15.3419 13.7758 15.8819C15.1478 16.7179 16.7179 15.1478 15.8819 13.7758C15.3419 12.8896 15.8205 11.7341 16.8291 11.4892C18.3903 11.1102 18.3903 8.8898 16.8291 8.51078C15.8205 8.26593 15.3419 7.11045 15.8819 6.22416C16.7179 4.85218 15.1478 3.28212 13.7758 4.11809C12.8896 4.65811 11.7341 4.17949 11.4892 3.17094ZM10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z" fill="#111827"/>
					</svg>
				</a-button>
				-->
				<!-- / Floating Action Button For Toggling Settings Drawer -->

				<!-- Sidebar Overlay -->
				<div class="sidebar-overlay" @click="sidebarCollapsed = true" v-show="!sidebarCollapsed"></div>
				<!-- / Sidebar Overlay -->

			</a-layout>
			<!-- / Layout Content -->

			<!-- Settings Drawer -->
			<DashboardSettingsDrawer :showSettingsDrawer="showSettingsDrawer" :navbarFixed="navbarFixed"
				:sidebarTheme="sidebarTheme" @toggleSettingsDrawer="toggleSettingsDrawer"
				@toggleNavbarPosition="toggleNavbarPosition" @updateSidebarTheme="updateSidebarTheme"
				@updateSidebarColor="updateSidebarColor"></DashboardSettingsDrawer>
			<!-- / Settings Drawer -->

		</a-layout>
		<!-- / Dashboard Layout -->

	</div>
</template>

<script>

import { Device } from '@twilio/voice-sdk';

import DashboardSidebar from '../components/Sidebars/DashboardSidebar';
import DashboardHeader from '../components/Headers/DashboardHeader';
import DashboardFooter from '../components/Footers/DashboardFooter';
import DashboardSettingsDrawer from '../components/Sidebars/DashboardSettingsDrawer';

import { isUserLoggedIn, currentToken } from '../services/v1/data_methods'

import { fetchDataJSON, getSocket } from '../services/v1/services'

export default ({
	components: {
		DashboardSidebar,
		DashboardHeader,
		DashboardFooter,
		DashboardSettingsDrawer,
	},
	mounted() {
		console.log('Dashboard layout mounted.');
		// Check if user is logged in.
		if (!isUserLoggedIn()) {
			// Redirect to sign in page.
			this.$router.push('/sign-in');

			return;
		}
		else {
			console.log('User is logged in.');
		}

		let self = this;

		// self.setupSocket();

		this.$eventHub.$on("userLoggedIn", async (item) => {
			console.log('user logged in, connecting socket', item);

			self.setupSocket();

		});

		this.$eventHub.$on("userLoggedOut", async (item) => {
			console.log('user logged out, disconnect socket', item);

			self.socket.disconnect();

		});

		this.$eventHub.$on("newCallDeviceRequest", async (item) => {
			console.log('call device data indir', item);

			// request channel token

			const channelTokenRes = await fetchDataJSON('projects/channels/' + item.channel + '/request_voice_token', true);

			const channelToken = channelTokenRes.data.token;

			const call_url = channelTokenRes.data.call_url;

			console.log('token obtained');


			console.log('setting up device')

			const auth_token = currentToken();


			const api_data = getVoiceAPI();

			const base_domain = api_data.url;

			const protocol = api_data.protocol;

			// create a new url to open in a new tab
			const new_link = call_url + '?token=' + channelToken + '&auth_token=' + auth_token + '&base_domain=' + base_domain + '&protocol=' + protocol+ '&channel_id=' + item.channel;
 
			// open the new tab
			window.open(new_link, '_blank');

			self.$eventHub.$emit("newCallDeviceRequestEnded", item);

		});
	},
	data() {
		return {
			// Sidebar collapsed status.
			sidebarCollapsed: false,

			// Main sidebar color.
			sidebarColor: "primary",

			// Main sidebar theme : light, white, dark.
			sidebarTheme: "light",

			// Header fixed status.
			navbarFixed: false,

			// Settings drawer visiblility status.
			showSettingsDrawer: false,

			device: null,

			socket: null,
		}
	},
	beforedestroy() {
		console.log('before destroy');
		this.socket.off('customEmit', this.onMessage);
		this.socket.off('connect', this.onConnect);

		this.socket.disconnect();

		this.$eventHub.$off("userLoggedIn");

		this.$eventHub.$off("userLoggedOut");

		this.$eventHub.$off("newCallDeviceRequest");


	},
	methods: {
		setupSocket() {
			console.log('setup socket');
			this.socket = getSocket();

			this.socket.off('customEmit', this.onMessage);
			this.socket.off('connect', this.onConnect);

			this.socket.on('customEmit', this.onMessage);
			this.socket.on('connect', this.onConnect);
		},
		onMessage(message) {
			console.log('socket message received', message);

			if (message.message.notification_type == 'whatsapp_message') {
				this.$eventHub.$emit("newWhatsAppMessage", message.message);
			}
		},
		onConnect(evt) {
			console.log('socket connected', evt);
		},
		initDevice(channelToken) {
			console.log('init device');

			let self = this;

			self.device = new Device(
				channelToken,
				{
					debug: true,
					allowIncomingWhileBusy: true,
				}
			);

			self.device.on('error', (twilioError, call) => {
				console.log('An error has occurred: ', twilioError);
			});

			self.device.on('ready', function (device) {
				console.log('Twilio.Device Ready!');
				self.device = device;
			});

			self.device.on('connect', function (conn) {
				console.log('Successfully established call!');
			});

			self.device.on('registered', () => {
				console.log('Successfully registered Twilio.Device');
			});

			self.device.on('registering', () => {
				console.log('Registering Twilio.Device');
			});

			// self.device = device;
		},
		toggleSidebar(value) {
			this.sidebarCollapsed = value;
		},
		toggleSettingsDrawer(value) {
			this.showSettingsDrawer = value;
		},
		toggleNavbarPosition(value) {
			this.navbarFixed = value;
		},
		updateSidebarTheme(value) {
			this.sidebarTheme = value;
		},
		updateSidebarColor(value) {
			this.sidebarColor = value;
		},
	},
	computed: {
		// Sets layout's element's class based on route's meta data.
		layoutClass() {
			return this.$route.meta.layoutClass;
		}
	},
})

</script>
