<template>

	<!-- Settings Drawer -->
	<a-drawer
		class="settings-drawer"
		:class="[ rtl ? 'settings-drawer-rtl' : '' ]"
		:placement="rtl ? 'left' : 'right'"
		:closable="false"
		:visible="showSettingsDrawer"
		width="360"
		:getContainer="() => wrapper"
		@close="$emit('toggleSettingsDrawer', false)"
	>

		<!-- Settings Drawer Close Button -->
		<a-button type="link" class="btn-close" @click="$emit('toggleSettingsDrawer', false)">
			<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
				<g id="close" transform="translate(0.75 0.75)">
					<path id="Path" d="M7.5,0,0,7.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
					<path id="Path-2" data-name="Path" d="M0,0,7.5,7.5" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5"/>
				</g>
			</svg>
		</a-button>
		<!-- / Settings Drawer Close Button -->
		
		<!-- Settings Drawer Content -->
		<div class="drawer-content">
			<h6>Configurator</h6>
			<p>Configure your global dashboard options.</p>

			<span v-if="current_user_info"><b>Account:</b> {{ current_user_info.username }}</span>

			

			<vue-loaders-ball-beat v-if="processing" color="blue" scale="1"></vue-loaders-ball-beat>
			
			<hr>


			<br><br>

			<div>
				<h6>Log Out</h6>
				<p>Log out of your current account.</p>
				
				
            	<a-button type="primary" @click="doLogout" block>
					Log Out
				</a-button>
        
			</div>

			<br><br>
			
			
			
		</div>
		<!-- / Settings Drawer Content -->

	</a-drawer>
	<!-- / Settings Drawer -->

</template>

<script>
	import {fetchDataJSON, fetchPostJSON} from '../../services/v1/services';

	import {saveCurrentUserProjectId, saveCurrentUserProjectRole, getCurrentUserProjectId, getCurrentUserProjectRole, getUserInfo, clearUserInfo, isUserLoggedIn} from '../../services/v1/data_methods';

	import 'vue-github-buttons/dist/vue-github-buttons.css'; // Stylesheet
	import VueGitHubButtons from 'vue-github-buttons';
	import Vue from 'vue';
	Vue.use(VueGitHubButtons, { useCache: true });

	export default ({
		props: {
			// Settings drawer visiblility status.
			showSettingsDrawer: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},

			// Header fixed status.
			navbarFixed: {
				type: Boolean,
				default: false,
			},

			// Drawer direction.
			rtl: {
				type: Boolean,
				default: false,
			},
		},
		updated: async function(){
			
			console.log('DashboardSettingsDrawer updated.');

			if(!isUserLoggedIn()){
				console.log('user not logged in, skipping drawer check');
				return;
			}

			//if it's been more than 5 minutes since the last fetch, fetch again

			if(this.lastProjectsFetchTime == null || (Date.now() - this.lastProjectsFetchTime) > 100000){

				this.processing = true;

				this.lastProjectsFetchTime = Date.now();

				this.processing = false;
			}
		},
		// on loaded

		data() {
			return {
				// The wrapper element to attach dropdowns to.
				wrapper: document.body,
				
				// Main sidebar color.
				sidebarColorModel: this.sidebarColor,
				
				// Main sidebar theme : light, white, dark.
				sidebarThemeModel: this.sidebarTheme,

				// Header fixed status.
				navbarFixedModel: this.navbarFixed,

				lastProjectsFetchTime: null,

				projects: [],

				current_project_id: null,

				current_user_info: null,

				preferences: {},

				processing: false,
			}
		},
		mounted: function(){
			// Set the wrapper to the proper element, layout wrapper.
			this.wrapper = document.getElementById('layout-dashboard') ;

			console.log('DashboardSettingsDrawer mounted.');

			if(!isUserLoggedIn()){
				console.log('user not logged in, skipping mounted proc');
				return;
			}

			this.current_user_info = getUserInfo();

			
		},

		beforedestroyed: function(){
			this.$eventHub.$off("currentProjectUpdated");
		},

		methods: {
			doLogout: function(){
				clearUserInfo();
				this.$eventHub.$emit("userLoggedOut", {time: Date.now()});
				this.$router.push('/sign-in');
			},
		},
	})

</script>

<style scoped>

.select-long{
	width: 100%;

	height: 40px;
}
</style>
