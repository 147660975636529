/*
=========================================================
Muse - Vue Ant Design Dashboard - v1.0.0
=========================================================

Product Page: https://www.creative-tim.com/product/vue-ant-design-dashboard
Copyright 2021 Creative Tim (https://www.creative-tim.com)
Coded by Creative Tim

=========================================================
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. 
*/

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import BootstrapVue from 'bootstrap-vue';
import SweetModal from 'sweet-modal-vue/src/plugin.js'
import Vue from 'vue'
import VueLoaders from 'vue-loaders';
import 'vue-loaders/dist/vue-loaders.css';
import Vuex from 'vuex';
import Gauge from '@chrisheanan/vue-gauge';














// import {BASE_URL} from '../env';
import App from './App.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'
import DefaultLayout from './layouts/Default.vue'

import router from './router'
// import './plugins/click-away'

import './scss/app.scss';













Vue.use(Antd);

Vue.use(Vuex);

Vue.use(Gauge);

Vue.component('gauge', Gauge);

Vue.use(VueLoaders);
Vue.use(SweetModal);
Vue.use(BootstrapVue);

Vue.config.productionTip = false


const store = new Vuex.Store({
  state: {
      currentToken: null,
      currentUsername: null,
      currentUserId: null,
      currentImageSrc: null,
      currentEditorSid: null,
      userType: null,
      selectedPackageId: null,
      trialUses: 0,
      inpaintTourCompleted: false,
      currentSid: null,

  },
  mutations: {
      clearToken (state) {
        state.currentToken = null;
      },
      setCurrentSid (state, sid) {
        console.log(sid)
        state.currentSid = sid;
      },
      setcurrentToken (state, token) {
          state.currentToken = token;
      },
      setcurrentImageScr (state, src) {
        state.currentImageSrc = src;
      },
      setLocalcurrentToken (state, token) {
        saveItem('tok', state.currentToken)
      },
      setcurrentUsername (state, username) {
        state.currentUsername = username;

        // saveItem('usn', state.currentUsername)
      },
      setcurrentUserId (state, userid) {
        state.currentUserId = userid;

        // saveItem('uid', state.currentUserId)
      },
      setUserType (state, usertype) {
        state.userType = usertype;
      },
      setTrialUses (state, uses) {
        state.trialUses = uses;
      }
  },
  actions: {
      "customEmit" () {
          // do something
          console.log('customedit')
      },
      clearData (context) {
        // state.currentToken = null;
        context.commit('clearToken');

        deleteItem('tok');

        deleteItem('tour_inpaint');

      },
      
      clearLocalToken (context) {

        deleteItem('tok');
      } 
  },
  getters: {
    // Check if user is logged in
    isUserLoggedIn: state => {

      if (getItem('tok')) {
        return getItem('tok').length > 4;
      }

      return state.currentToken?state.currentToken.length > 4: false;
    },

    getCurrentToken: state => {

      if (state.currentToken) {

        return state.currentToken
      }

      if (getItem('tok')) {
        
        const token = getItem('tok');
        
        state.currentToken = token;

        return token;
      }
    },
    getCurrentUsername: state => {

      if (getItem('usn')) {
        return getItem('usn');
      }

      return state.currentUsername;
    },
    getCurrentSid: state => {

      return state.currentSid;
    },
    getInpaintTourComplete: state => {

      return state.inpaintTourCompleted;
    },
    getCurrentUserId: state => {

      if(state.currentUserId) {
        return state.currentUserId;
      }

      if (getItem('uid')) {
        return getItem('uid');
      }
    },
    getCurrentImageSrc: state => {

      return state.currentImageSrc;
    },
    getisTrial: state => {
      return state.isTrial;
    },
    gettrialUses: state => {
      return state.trialUses;
    },
    getisTrialMaxedOut: state => {
      return state.trialUses >= 15 && state.isTrial;
    }
  },
})


// Vue.prototype.$baseurl = BASE_URL;

Vue.prototype.$eventHub = new Vue(); // Global event bus

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')